
let embeddedFrame: HTMLIFrameElement, widgetFrame: HTMLIFrameElement | null
let nonce = Date.now()

type Callback = (info: any) => void

type Options = {
  cid: string
  lastName: string
  ln: string
  verify?: boolean

  onDialogDisplayed?: (method: string) => void
  onDialogClosed?: (submitted: boolean) => void
  onSubmitted?: (info:any) => void
}

(window as any).dstPaymentForm = (root: HTMLElement, callback?: Callback, options?: Options) => {

  const defaults: Options = {
    cid: '',
    lastName: '',
    ln: 'en',
    verify: true
  }

  const opt = (typeof callback === 'object' ? callback as Options : options) || defaults
  const cb = typeof callback === 'function' ? callback : undefined

  const key = root.getAttribute('key') as string

  if (embeddedFrame && embeddedFrame.parentElement === root) {
    root.removeChild(embeddedFrame)
  }

  const allowedKeys = Object.keys(defaults)
  const params = Object
    .entries(opt)
    .filter(([k, v]) =>
      allowedKeys.includes(k)
      && (typeof v === 'string' || typeof v === 'number' || typeof v === 'boolean')
    )
    .reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: value
      }),
      {}
    )

  embeddedFrame = document.createElement('iframe')
  embeddedFrame.frameBorder = "0"
  embeddedFrame.style.border = 'none'
  embeddedFrame.style.width = '100%'
  embeddedFrame.style.height = '0px'
  embeddedFrame.style.overflow = 'hidden'
  embeddedFrame.src = process.env.BASE_URL + '/index.html?'
    + encodeURIComponent(key) + '&'
    + encodeURIComponent(JSON.stringify(params))

  root.appendChild(embeddedFrame)

  function createWidget(method:string) {
    widgetFrame = document.createElement('iframe')
    widgetFrame.frameBorder = "0"
    widgetFrame.style.border = 'none'
    widgetFrame.style.width = '100%'
    widgetFrame.style.height = '100%'
    widgetFrame.style.left = '0'
    widgetFrame.style.top = '0'
    widgetFrame.style.position = 'fixed'
    widgetFrame.style.backgroundColor = 'transparent'
    //widgetFrame.style.display = 'none'
    widgetFrame.style.zIndex = '999'
    widgetFrame.src = process.env.BASE_URL + '/widget.html?'
      + encodeURIComponent(method) + '&'
      + encodeURIComponent(key) + '&'
      + encodeURIComponent(JSON.stringify(params))

    root.appendChild(widgetFrame)
  }

  function destroyWidget() {
    if (widgetFrame) {
      if (widgetFrame.parentElement === root) {
        root.removeChild(widgetFrame)
      }
      widgetFrame = null
    }
  }

  class DataCollectedEvent extends Event {
    token: string

    constructor(type: string, token: string) {
      super(type)
      this.token = token
    }
  }

  const eventListener = (v: number) => (event: MessageEvent) => {
    if (v !== nonce) {
      return
    }
    if (event.data.source === 'dst') {
      switch (event.data.type) {
        case 'method-selected':
          createWidget(Buffer.from(JSON.stringify({
            method: event.data.value,
            cid: event.data.cid,
            lastName: event.data.lastName,
            country: event.data.country,
            fee: event.data.fee
          })).toString('base64'))
          if (opt.onDialogDisplayed) {
            opt.onDialogDisplayed(event.data.value)
          }
          break
        case 'close':
          destroyWidget()
          if (opt.onDialogClosed) {
            opt.onDialogClosed(event.data.submitted)
          }
          break
        case 'data-collected':
          embeddedFrame.contentWindow && embeddedFrame.contentWindow.postMessage(event.data, '*')
          root.dispatchEvent(new DataCollectedEvent('finished', event.data.info))
          if (cb) {
            cb(event.data.info)
          }
          if (opt.onSubmitted) {
            opt.onSubmitted(event.data.info)
          }
          //embeddedFrame.style.height = '25px'
          break
        case 'set-height':
          embeddedFrame.style.height = (event.data.height + 10) + 'px'
          break;
        }
    }
  }

  nonce = Date.now()
  window.addEventListener('message', eventListener(nonce))
}
